import React, { useState, useEffect, useRef } from 'react';
import { RxDividerVertical } from "react-icons/rx";

const TotalMessages = ({ id, type_id, Key1, Key2, Url, Data }) => {
  const [data, setData] = useState([]);
  const refDiv = useRef(null);
  const [chartSize, setChartSize] = useState(300);

  /* Responsive container takes container dimensions for resizes */
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentRect.width > 0 && entry.contentRect.height > 0) {
          setChartSize(Math.min(entry.contentRect.width, entry.contentRect.height));
        }
      }
    });
    resizeObserver.observe(refDiv.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  /* Data Obtain */
  useEffect(() => {
    let url = Url;
    let key1 = Key1;
    let key2 = Key2;
  
    fetch(url)
      .then(response => response.json())
      .then(data => { 
        const formattedData = data.map(item => ({
          value1: item[key1],
          value2: item[key2]
        }));
        setData(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id, type_id]);

  return (
    <div ref={refDiv} className='Cards'>
      {data.length > 0 ? (
        <ul>
          {data.map((item, index) => (
            <li key={index}>
              <div className='container'>
                <div className='column'>
                <div className='txt'>
                    Bot
                  </div>
                  <div className='numbmesages'>
                    {item.value1}
                  </div>
               
                </div>
                <div className='column'>
                  <div className='line'>
                  <RxDividerVertical  />
                  </div>
                </div>
                <div className='column'>
                <div className='txt'>
                    Usuarios
                  </div>
                  <div className='numbmesages'>
                    {item.value2}
                  </div>
               
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>Cargando datos...</p>
      )}
      <h3>{Data}</h3>
    </div>
  );
};

export default TotalMessages;