import React, { useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Select from "react-select";
import IconoBot from "../../../utils/IconoBot";
import IconoUser from "../../../utils/IconoUser";
import SearchIcon from "../../../utils/SearchIcon";
import { IoMdArrowDropdown } from "react-icons/io";
const TabHistoMsg = ({ id,Url}) => {

  /*Default date*/
  const currentDate = new Date();
  const weekAgoDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const defaultStartDate = weekAgoDate.toISOString().split("T")[0];
  const defaultEndDate = currentDate.toISOString().split("T")[0];
  
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [companyFilter, setCompanyFilter] = useState("");
  const [userFilter, setUserFilter] = useState(""); 
  const [companyOptions, setCompanyOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [filteredUserOptions, setFilteredUserOptions] = useState([]); 
  const [userPlaceholder, setUserPlaceholder] = useState("Antes seleccione una empresa");
  const [userPlaceholder3, setUserPlaceholder3] = useState("Buscar usuario");
  const [isUserDisabled, setIsUserDisabled] = useState(true);
  const userSelectOptions = filteredUserOptions.map(user => ({ value: user, label: user }));
  const [isDateDescending, setIsDateDescending] = useState(true);


/*Abbreviated text, it takes up two or three letters after the spaces*/
  const displayAbbreviation = (name) => {
    if (name.includes(" ")) {
      const index = name.indexOf(" ");
      return name.substring(0, index) + ' ' + name.substring(index + 1, index + 4); 
    }
    return name;
  };
  
/*Generate a format for a date*/
  const dateFormatter = (dateString) => {
    const date = new Date(dateString);
    if (date.getUTCHours() === 0) {
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(date);
  };
  
/*Data Obtain */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Url);
        const rawData = await response.json();
        let tempData = rawData.map((item) => ({
          de: item["De"],
          companyName: item["Company Name"],
          fecha: item["Fecha"],
          mensaje: item["Mensaje"],
          companyId: item["Company ID"],
          conversacionId: item["Conversacion Id"],
        }));

        let tempCompanyOptions = [...new Set(tempData.map(item => item.companyName))];
        let tempUserOptions = [...new Set(tempData.map(item => item.de))];

        setData(tempData);
        setCompanyOptions(tempCompanyOptions);
        setUserOptions(tempUserOptions); 

        if (tempData.length > 0) {
            const latestDate = new Date(Math.max(...tempData.map(item => new Date(item.fecha))));
            setEndDate(latestDate.toISOString().split('T')[0]);
        }
      } catch (error) {
      }
    };

    fetchData();
  }, [id, Url]);

  /*Defines dates of timepicker */
  useEffect(() => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    if (end) {
      end.setDate(end.getDate() + 1);
    }
    const filtered = data.filter(item => {
      const itemDate = new Date(item.fecha);
      const isDateInRange = (!start || itemDate >= start) && (!end || itemDate < end);
      const isCompanyMatch = !companyFilter || item.companyName === companyFilter;
      const isUserMatch = !userFilter || item.de === userFilter;
      return isDateInRange && isCompanyMatch && isUserMatch;
    });

    const sorted = filtered.sort((a, b) => {
      if (isDateDescending) {
        return new Date(b.fecha) - new Date(a.fecha);
      } else {
        return new Date(a.fecha) - new Date(b.fecha);
      }
    });

    setFilteredData(sorted);
  }, [data, startDate, endDate, companyFilter, userFilter, isDateDescending]);

  const handleDateSortClick = () => {
    setIsDateDescending(!isDateDescending);
  };
  /*company and user selector */
  useEffect(() => {
    if (!companyFilter) {
      setFilteredUserOptions([]);
      setUserFilter('');
      setUserPlaceholder("Antes una empresa");
      setUserPlaceholder3("Buscar usuario");
    } else {
      const specificUserOptions = data
        .filter(item => item.companyName === companyFilter)
        .map(item => item.de);

      setFilteredUserOptions([...new Set(specificUserOptions)].map(user => ({ value: user, label: user })));
      setIsUserDisabled(false);
    }
  }, [companyFilter, data]);
  
  /*enable and disable user selector*/
  useEffect(() => {
    const isDisabled = !companyFilter; 
    setIsUserDisabled(isDisabled);
  }, [companyFilter]); 

  return (
    <div className="TabHistoriMensajesU">
      <div className="titulo">
        <h2>Historial de mensajes</h2>
      </div>
      <div className="filtro">
        <div className='first'>
          <div className='container-input'>
            <txt>Fecha inicio</txt>
            <input
              type="date" 
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Fecha inicial"
            />
          </div>
          <div className='container-input'>
            <txt>Fecha final</txt>
            <input
              type="date" 
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="Fecha final"
            />
          </div>
        </div>
        <div className='second'>
        <div className='container-input'>  
            <txt>Empresa</txt>  
            <select
              value={companyFilter}
              onChange={(e) => {
                const newCompanyFilter = e.target.value;
                setCompanyFilter(newCompanyFilter);
                setUserFilter(''); 
                setUserPlaceholder("Antes seleccione una empresa");
                setUserPlaceholder3("Buscar usuario");
                if (!newCompanyFilter) {
                    setFilteredUserOptions([]);
                } else {
                    const specificUserOptions = data.filter(item => item["Company Name"] === newCompanyFilter).map(item => item["De"]);
                    setFilteredUserOptions([{ value: '', label: 'Todos' }, ...new Set(specificUserOptions)]);
                }
            }}
            
            >
              <option value="">Todos</option>
              {companyOptions.map((company, index) => (
                <option key={index} value={company}>{displayAbbreviation(company)}</option>
              ))}
            </select>
          </div>
          <div className='container-inputc'>
            <txt2>Usuario</txt2>
            <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={userFilter ? { value: userFilter, label: userFilter } : null}  
                onChange={(option) => setUserFilter(option ? option.value : '')}  
                options={filteredUserOptions}
                placeholder={isUserDisabled ? "seleccione una empresa" : "Buscar usuario"}
                isClearable
                isSearchable
                isDisabled={isUserDisabled}
                components={{
                    DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline:"0.5vw" ,width: '1vw', height: 'auto'}}>
                        <SearchIcon className="iconoUserSmall" />
                    </div>
                ),
              }}
              styles={{
                clearIndicator: provided => ({
                  ...provided,
                  color: 'gray',
                  width: '50%', 
                  height: 'auto',
                }),
                singleValue: provided => ({
                  ...provided,
                  color: 'gray', 
                  fontSize: '50%', 
                  width:'100%',
                  
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: 'gray', 
                  fontSize: '50%', 
                  width:'100%',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                  color: state.isSelected ? '#FF00B1' : 'grey',
                  cursor: 'pointer'
                }),
              }}
            />
          </div>
        </div>  
      </div>
      {filteredData.length > 0 ? (
          <div className="table-container">
                      
            <table>
              <colgroup>
                <col style={{ width: "20%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "60%" }} />
              </colgroup>
              <thead>
              <tr>
              <th>DE</th>
              <th >
              <div
      onClick={handleDateSortClick} style={{ cursor: 'pointer', display:'flex', alignItems: 'center' }}
      >
 FECHA <IoMdArrowDropdown 
        style={{ transform: isDateDescending ? 'rotate(0deg)' : 'rotate(180deg)', 
                  transition: 'transform 0.2s'}} />
        
      </div>
     
    </th>
    <th>MENSAJE</th>
  </tr>
</thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={`${item.conversacionId}-${index}`}>
                    <td className="company">
                    {displayAbbreviation(item.companyName)}
                      {item.de === "BOT" ? (
                        <IconoBot className="iconoBotSmall" />
                      ) : (
                        <IconoUser className="iconoUserSmall" />
                      )}
                      <txt className="de">{item.de}</txt>
                    </td>
                    <td className="itemfecha">{dateFormatter(item.fecha)}</td>
                    <td className="mensaje">{item.mensaje}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="sticky-footer"></div>
          </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default TabHistoMsg;