import ConstantsComponent from "../../../claims/Constants.jsx";
import TabHistoMsg from "../../Graphs/Admin/TabHistoAdmin.jsx";
import UrlProvider from "../../../Api/UrlProvider.jsx";
const Board_ExplorerUser= ()=>{
//send user id to Admin explorer board
    const {ID} = ConstantsComponent();
     /*Url */
     const Url= `${UrlProvider.defaults.baseURL}/Historial_user/${ID}`
    return (
            <div className="TabExplorerCliente_User">
                <TabHistoMsg id={ID} Url={Url}/> 
            </div>
        
    );
};
export default Board_ExplorerUser;