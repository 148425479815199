import React, { useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import Statistics from '../components/Statistics';
import Navbar from '../components/Navbar';
import Explorer from '../components/Explorer';
import Trends from '../components/TrendsT';
import { Routes, Route } from 'react-router-dom';
import { useSidebarContext } from '../components/Sidebar/SidebarContext';
import Popup from '../components/popup';
import { isMobile } from 'react-device-detect';


const Datav = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { isCollapsed } = useSidebarContext();
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const handleOrientationChange = () => {
            if (isMobile && window.innerHeight > window.innerWidth) {
                setShowPopup(true); 
            } else {
                setShowPopup(false); 
            }
        };

        // Establece el estado inicial según la orientación actual
        handleOrientationChange(); 

        window.addEventListener('resize', handleOrientationChange); 
        return () => window.removeEventListener('resize', handleOrientationChange);
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className={`datav-container ${isCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'}`}>
            {isAuthenticated && (
                <>
                    {showPopup && <Popup onClose={() => setShowPopup(false)} />}
                    <div className='datav-container'>
                        <Sidebar />
                        <Navbar />
                        <div className='distribution'>
                            <Routes>
                                <Route path="Home" element={<Statistics />} />
                                <Route path="Trends" element={<Trends />} />
                                <Route path="explorer" element={<Explorer />} />
                            </Routes>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Datav;
