//Recolection and distribution in an array of aplications uses a users claims

import UserClaims from "./UserClaims";

const ConstantsComponent = () => {
    const userClaimsStrings = UserClaims();
    //console.log("UserClaims:", userClaimsStrings);

    const getClaimValue = (claimKey) => {
      const claim = userClaimsStrings.find(claim => claim.startsWith(`${claimKey}:`));
      return claim ? claim.split(":")[1]?.trim() ?? 'No disponible' : 'No disponible';
  };

    //string describes a usertype: Admin, Client, User
    const ROL = getClaimValue("extension_AccessType");

    //Int describes a user id, for testing using 1 
    const ID = getClaimValue("extension_User_id");

    const NAME = getClaimValue("name");

    const EMAIL = getClaimValue("extension_Email");
    
//Int describes de asigned number for a user type 1:Admin, 2:User, 3:Client
    const TYPE_ID = getClaimValue("extension_Type_id");

    const COMPANY_ID = getClaimValue("extension_Company_id");
    
    const PROGRAM = getClaimValue("extension_Program_name");

    const COMPANY = getClaimValue("extension_Companyname");
    
    return {
      
      ROL,
      ID,
      NAME,
      EMAIL,
      TYPE_ID,
      COMPANY_ID,
      PROGRAM, 
      COMPANY
    };
};

export default ConstantsComponent;