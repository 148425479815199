
import React, { useState, useEffect,useRef } from 'react';
import  calendar  from '../../../assets/calendar.svg';
import { ReactComponent as Fondo } from '../../../assets/Fondoinfo.svg';

const InfoUserCard = ({name}) => {
  const [currentDate, setCurrentDate] = useState('');
  const refDiv = useRef(null);
  const [chartSize, setChartSize] = useState(0);

/*Responsive container takes container dimensions for resizes */
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentRect.width > 0 && entry.contentRect.height > 0) {
          setChartSize(Math.min(entry.contentRect.width, entry.contentRect.height));
        }
      }
    });
    resizeObserver.observe(refDiv.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const getCurrentDate = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  // Llama a getCurrentDate para establecer la fecha actual
  useState(() => {
    setCurrentDate(getCurrentDate());
  }, []);

  return (
    <div ref={refDiv} className='infoUserCard'>
      <Fondo  className="fondoInfo" style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              zIndex: 1,
              height: '100%', // Ajusta el ancho del Fondo al 100% del contenedor
              width: 'auto', // Altura automática para mantener la proporción
              
            }}/>
     
      <div className='card'>
          <div className="row1">
              <div className='titulo'>Bienvenido <nombre>{name}</nombre></div>
          </div>
          <div className="row2">
              <div className='fecha'>
                <div className='txt'>{currentDate}</div>
                <img src={calendar} className="calendar" alt="calendar" />
              </div>
          </div>
      </div>
    </div>

);
};

export default InfoUserCard;
