import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { useCSSVariable } from '../../../utils/Colors';

const GraphBars = ({ type_id, dataC, dataM, Url }) => {
  const defaultStartDate = new Date();
  const defaultEndDate = new Date(defaultStartDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const formattedOneWeekAgo = defaultEndDate.toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(formattedOneWeekAgo);
  const [endDate, setEndDate] = useState('');

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  let DataC = dataC;
  let DataM = dataM;
  const [totalByUser, setTotalByUser] = useState({});

  const [chartSize, setChartSize] = useState(0);
  const refDiv = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        requestAnimationFrame(() => {
          let newSize = Math.min(entry.contentRect.width, ((entry.contentRect.height) - entry.contentRect.height / 2.8));

          if (window.innerHeight <= 500) {
            newSize = Math.min(entry.contentRect.width, ((entry.contentRect.height) - entry.contentRect.height / 3));
          }
          if (window.innerHeight <= 390) {
            newSize = Math.min(entry.contentRect.width, ((entry.contentRect.height) - entry.contentRect.height / 3));
          }

          setChartSize(newSize);
        });
      }
    });
    resizeObserver.observe(refDiv.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const displayAbbreviation = (name) => {
    if (name.includes(" ")) {
      const index = name.indexOf(" ");
      return name.substring(0, index) + ' ' + name.substring(index + 1, index + 2);
    }
    return name;
  };

  const Lightpink = useCSSVariable('--Lightpink');
  const blue = useCSSVariable('--blue');
  const text = useCSSVariable('--text');
  const title = useCSSVariable('--subtitle');

  const renderLegend = (props) => {
    const { payload } = props;
    let fontSize = '70%';
    let marginTop = 5;
    let marginRight = '5%';
    let circleCx = '5';
    let circleCy = '5';
    let circleR = '5';
    let marginBottom = '1%';

    if (window.innerHeight <= 500) {
      fontSize = '1.6vh';
      marginTop = -10;
      circleCx = '4';
      circleCy = '4';
      circleR = '4';
    }
    if (window.innerHeight <= 390) {
      fontSize = '1.6vh';
      marginTop = -10;
      circleCx = '3.5';
      circleCy = '3.5';
      circleR = '3.5';
    }

    return (
      <ul style={{
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{
            display: 'flex',
            alignItems: 'center',
            marginRight,
            marginBottom,
            marginTop
          }}>
            <svg height="10" width="10">
              <circle cx={circleCx} cy={circleCy} r={circleR} fill={entry.color} />
            </svg>
            <span style={{ marginLeft: 3, fontSize, fontWeight: '600', color: title }}>{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  function renderUserMessage() {
    if (type_id == 3) {
      return (
        <div className='container-input'>
          <txt>
            Usuario
          </txt>
          <select
            value={selectedUserId}
            onChange={(e) => setSelectedUserId(e.target.value)}
          >
            <option value="">Todos</option>
            {users.map(user => (
              <option key={user.id} value={user.id}>
                {displayAbbreviation(user.name)}
              </option>
            ))}
          </select>
        </div>
      );
    } else if (type_id == 2) {
      return null;
    }
  }

  const dateFormatter = (dateString) => {
    const date = new Date(dateString);
    if (date.getUTCHours() === 0) {
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(date);
  }

  const renderCustomBarLabel = (props) => {
    const { x, y, width, index } = props;
    const username = filteredData[index].username;
    let fontSize = '60%';
    if (window.innerHeight <= 500) {
      fontSize = '1.6vh';
    }
    if (window.innerHeight <= 390) {
      fontSize = '1.6vh';
    }
    return selectedUserId === '' ? (
      <text
        x={x + width / 2}
        y={y - 10}
        fill={text}
        fontSize={fontSize}
        fontFamily="Open Sans"
        fontWeight="500"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {(username)}
      </text>
    ) : null;
  };

  useEffect(() => {
    const url = Url;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setData(data);

        if (data.length > 0) {
          const latestDate = new Date(Math.max(...data.map(item => new Date(item.date))));
          setEndDate(latestDate.toISOString().split('T')[0]);
        }

        const uniqueUsers = data.reduce((acc, item) => {
          if (!acc.find(user => user.id === item.user_id)) {
            acc.push({ id: item.user_id, name: item.username });
          }
          return acc;
        }, []);

        setUsers(uniqueUsers);
      })
      .catch(error => console.error('Error:', error));
  }, [Url]);

  const calculateTotalByUser = () => {
    const totals = {};
    if (!selectedUserId) {
      filteredData.forEach(item => {
        const date = new Date(item.date);
        const formattedDate = dateFormatter(date);
        if (!totals[formattedDate]) {
          totals[formattedDate] = { messages: 0, conversations: 0, date: formattedDate };
        }
        totals[formattedDate].messages += item[DataM];
        totals[formattedDate].conversations += item[DataC];
      });
      setTotalByUser(totals);
    }
  };

  useEffect(() => {
    calculateTotalByUser();
  }, [filteredData]);

  useEffect(() => {
    let filtered = Array.isArray(data) ? data : [];
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    if (startDate) {
      filtered = filtered.filter(item => new Date(item.date) >= start);
    }
    if (endDate) {
      filtered = filtered.filter(item => new Date(item.date) <= end);
    }
    if (selectedUserId) {
      filtered = filtered.filter(item => {
        return item.user_id.toString() === selectedUserId.toString();
      });
    }

    setFilteredData(filtered);
  }, [data, startDate, endDate, selectedUserId]);

  if (!data) return <div>Loading...</div>;

  function rendergraph() {
    let yAxisFontSize = '1.5vh';
    if (window.innerHeight <= 500) {
      yAxisFontSize = '1.8vh';
    }
    if (window.innerHeight <= 390) {
      yAxisFontSize = '1.8vh';
    }

    if (type_id == 3) {
      if (selectedUserId) {
        return (
          <ResponsiveContainer width="100%" height={chartSize}>
            <BarChart data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={dateFormatter} tick={{ fontSize: yAxisFontSize }} />
              <YAxis tick={{ fontSize: yAxisFontSize }} />
              <Tooltip />
              <Legend content={renderLegend} />
              <Bar dataKey={DataM} fill={blue} name="Mensajes">
                <LabelList content={renderCustomBarLabel} />
              </Bar>
              <Bar dataKey={DataC} fill={Lightpink} name="Conversaciones" />
            </BarChart>
          </ResponsiveContainer>
        );
      } else {
        return (
          <ResponsiveContainer width="100%" height={chartSize}>
            <BarChart data={Object.values(totalByUser)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={dateFormatter} tick={{ fontSize: yAxisFontSize }} />
              <YAxis tick={{ fontSize: yAxisFontSize }} />
              <Tooltip />
              <Legend content={renderLegend} />
              <Bar dataKey="messages" fill={blue} name="Mensajes" />
              <Bar dataKey="conversations" fill={Lightpink} name="Conversaciones" />
            </BarChart>
          </ResponsiveContainer>
        );
      }
    } else if (type_id == 2) {
      return (
        <ResponsiveContainer width="100%" height={chartSize}>
          <BarChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={dateFormatter} tick={{ fontSize: yAxisFontSize }} />
            <YAxis tick={{ fontSize: yAxisFontSize }} />
            <Tooltip />
            <Legend content={renderLegend} />
            <Bar dataKey={DataM} fill={blue} name="Mensajes">
              <LabelList content={renderCustomBarLabel} />
            </Bar>
            <Bar dataKey={DataC} fill={Lightpink} name="Conversaciones" />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  }

  const handleFocus = (e) => {
    e.target.type = 'date';
  };

  const handleBlur = (e) => {
    if (e.target.value === '') {
      e.target.type = 'text';
    }
  };

  return (
    <div ref={refDiv} className='GraphBars'>
      <div className='titulo'>
        <h2>Mensajes y conversaciones por día</h2>
      </div>
      <div className='filtro'>
        <div className='first'>
          <div className='container-input'>
            <txt>
              Fecha inicio
            </txt>
            <input
              type="date"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className='container-input'>
            <txt>
              Fecha Final
            </txt>
            <input
              type="date"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className='second'>
          {renderUserMessage()}
        </div>
      </div>
      <div className='containerBar'>
        {rendergraph()}
      </div>
    </div>
  );
};

export default GraphBars;