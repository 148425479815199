import React, { useState, useEffect, useRef } from "react";
import IconoBot from "../../../utils/IconoBot";
import IconoUser from "../../../utils/IconoUser";
import Select from "react-select";
import SearchIcon from "../../../utils/SearchIcon";
import { IoMdArrowDropdown } from "react-icons/io";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import ExportToExcel from "../../ExportToExcel";

const TabHistoMsg = ({ id, Url }) => {
  // Set default start and end dates
  const currentDate = new Date();
  const weekAgoDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const defaultStartDate = weekAgoDate.toISOString().split('T')[0];
  const defaultEndDate = currentDate.toISOString().split('T')[0];

  // State variables
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [deFilter, setDeFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [deOptions, setDeOptions] = useState([]);
  const [isDateDescending, setIsDateDescending] = useState(true);
  const [isLoading, setIsLoading] = useState(true); // New loading state

  // Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Tamaño de la tabla
  const [tableSize, setTableSize] = useState(0);
  const refTableDiv = useRef(0);

  // Function to abbreviate text, takes up to three letters after spaces
  const displayAbbreviation = (name) => {
    if (name.includes(" ")) {
      const index = name.indexOf(" ");
      return name.substring(0, index) + ' ' + name.substring(index + 1, index + 4); 
    }
    return name;
  };

  // Function to format dates
  const dateFormatter = dateString => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return `${formattedDate} ${formattedTime}`;
  };

  // Fetch data from the endpoint
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true when fetching data
      try {
        const response = await fetch(Url);
        const rawData = await response.json();
        const tempData = rawData.map(item => ({
          de: item["DE"],
          companyName: item["Company Name"],
          fecha: item["Fecha"],
          mensaje: item["Mensaje"],
          companyId: item["Company Id"],
          conversacionId: item["Conversation ID"],
          categoria: item["Categoría"] // Add category to the data structure
        }));
        setData(tempData);

        // Set the latest date as the default end date
        if (tempData.length > 0) {
          const latestDate = new Date(Math.max(...tempData.map(item => new Date(item.fecha))));
          setEndDate(latestDate.toLocaleDateString('en-CA'));
        }
        // Set options for the user filter
        const tempDeOptions = [...new Set(tempData.map(item => item.de))];
        setDeOptions(tempDeOptions);

        // Set options for the category filter
        const tempCategoryOptions = [...new Set(tempData.map(item => item.categoria))];
        setCategoryOptions(tempCategoryOptions);
      } catch (error) {
        // Handle errors here
      }
      setIsLoading(false); // Set loading to false after fetching data
    };

    fetchData();
  }, [id, Url]);

  // Responsive
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        requestAnimationFrame(() => {
          let containerHeight = entry.contentRect.height;
          let containerWidth = entry.contentRect.width;
          let rowsPerPage = Math.min(Math.floor(containerHeight / 100), 5); // Altura máxima de cada fila es 90px, máximo 4 filas
          if (containerWidth >= containerHeight) {
            rowsPerPage = Math.min(Math.floor(containerHeight / 100), 5); // Máximo 5 filas para pantallas anchas o cuadradas
          } 
          
          else {
            rowsPerPage = Math.min(Math.floor(containerHeight / 100), 5); // Máximo 8 filas para pantallas más altas que anchas
          }
          if (window.innerHeight <= 390) {
            rowsPerPage = 2;
          } else if (window.innerHeight <= 500) {
            rowsPerPage = 2;
          }

          setItemsPerPage(rowsPerPage);
          setTableSize(containerHeight);
        });
      }
    });
    
    resizeObserver.observe(refTableDiv.current);
    
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Filter and sort data based on the selected dates and filters
  useEffect(() => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    if (end) {
      end.setDate(end.getDate() + 1);  // Add one day to the end date
    }
    const filtered = data.filter(item => {
      const itemDate = new Date(item.fecha);
      const isDateInRange = (!start || itemDate >= start) && (!end || itemDate < end);
      const isUserMatch = !deFilter || item["de"] === deFilter;
      const isCategoryMatch = !categoryFilter || item["categoria"] === categoryFilter;
      return isDateInRange && isUserMatch && isCategoryMatch;
    });

    const sorted = filtered.sort((a, b) => {
      if (isDateDescending) {
        return new Date(b.fecha) - new Date(a.fecha);
      } else {
        return new Date(a.fecha) - new Date(b.fecha);
      }
    });

    setFilteredData(sorted);
  }, [data, startDate, endDate, deFilter, categoryFilter, isDateDescending]);

  // Handle sorting by date
  const handleDateSortClick = () => {
    setIsDateDescending(!isDateDescending);
  };

  // Función para cambiar de página
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };

  // Datos para la página actual
  const currentData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Check if no data matches the filters
  const noDataMessage = 
    deFilter && deFilter !== 'BOT' && 
    categoryFilter && categoryFilter !== 'NA' && categoryFilter !== 'No registrado' ? 
    'No hay datos' : 'Cargando datos...';

  return (
    <div ref={refTableDiv} className="TabHistoriMensajesU">
      <div className="titulo">
        <h2>Historial de mensajes</h2>
      </div>
      
      <div  className="container_graph">
        <div className="filtro">
          <div className='first'>
            <div className='container-input'>
              <txt>Fecha inicio</txt>
              <input
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                placeholder="Fecha inicial"
              />
            </div>
            <div className='container-input'>
              <txt>Fecha final</txt>
              <input
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                placeholder="Fecha final"
              />
            </div>
          </div>
          <div className='second'>
            <div className='container-inputc'>
              <txt2>Usuario</txt2>
              <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={deOptions.find(option => option.value === deFilter)}
                onChange={option => setDeFilter(option ? option.value : '')}
                options={deOptions.map(de => ({ value: de, label: de }))}
                placeholder={"Buscar usuario"}
                isClearable
                isSearchable
                components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', height: 'auto' }}>
                      <SearchIcon className="iconoUserSmall" />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'
                  }),
                  
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>
            <div className='container-inputc'>
              <txt2>Categoria</txt2>
              <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={categoryOptions.find(option => option.value === categoryFilter)}
                onChange={option => setCategoryFilter(option ? option.value : '')}
                options={categoryOptions.map(category => ({ value: category, label: category }))}
                placeholder={"Buscar categoria"}
                isClearable
                isSearchable
                components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', height: 'auto' }}>
                      <SearchIcon className="iconoUserSmall" />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',
    
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'
    
                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <p>Cargando datos...</p>
        ) : filteredData.length > 0 ? (
          <div className="table-container">
            <table>
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "50%" }} />
              </colgroup>

              <thead>
                <tr>
                  <th>DE</th>
                  <th>CATEGORÍA</th> {/* New column header */}
                  <th>
                    <div onClick={handleDateSortClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                      FECHA <IoMdArrowDropdown style={{ transform: isDateDescending ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.2s' }} />
                    </div>
                  </th>
                  <th>MENSAJE</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={`${item.conversacionId}-${index}`}>
                    <td className="company">
                    <txt className="de">{item.de}</txt>
                    
                      
                      {item.de === "BOT" ? (
                        <IconoBot className="iconoBotSmall" />
                      ) : (
                        <IconoUser className="iconoUserSmall" />
                      )}
                    <txt className="de_n">{displayAbbreviation(item.companyName)}</txt>  
                    </td>
                    <td className="mensaje">{item.categoria}</td> {/* New column data */}
                    <td className="itemfecha">{dateFormatter(item.fecha)}</td>
                    <td className="mensaje">{item.mensaje}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <BiChevronsLeft style={{ color: '#595959', fontSize: '110%' }}/>
              </button>
              <span style={{ textAlign: 'center', fontSize:'60%', color: '#595959'}}>Página {currentPage} de {Math.ceil(filteredData.length / itemsPerPage)}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
              >
                 <BiChevronsRight style={{ color: '#595959', fontSize: '110%' }}/>
              </button>
              
            </div>
            <div style={{
              width:"100%",
              maxWidth: "950px",
              marginBottom:"1%"
              }}>
            <ExportToExcel/>
            </div>
          </div>
          
        ) : (
          <p>{noDataMessage}</p>
        )}
     
      </div>
    </div>
  );
};

export default TabHistoMsg;
