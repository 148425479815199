import React from 'react';
import SignOutButton from '../components/Buttons/SignOutButton';
import ConstantsComponent from '../claims/Constants';
function Navbar() {
  /*Retrieve user name and email from the ConstantsComponent*/
  const { NAME, EMAIL} = ConstantsComponent();
  /*Render a header that includes a sign-out button component*/
  return (
    <header className='header_nav'>
      <SignOutButton name={NAME} Email= {EMAIL} />
    </header>
  );
}

export default Navbar;
