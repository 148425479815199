import { loginRequest } from '../../context/authConfig'; 
import { useMsal} from '@azure/msal-react';

//button activate the call to login in user flow
const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
    //Popup, not working well
        if (loginType === 'popup') {
            instance.loginPopup(loginRequest).catch(e => {
                console.error(e);
            });
    //Redirect
        } else if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch(e => {
                console.error(e);
            });
        }
    };

//The aplication uses redirect 
    return <button className='submit-button' onClick={() => handleLogin('redirect')}>Inicio</button>;
};

export default SignInButton;