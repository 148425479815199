/**
 * Compares the issuing policy claim (tfp/acr) in the ID token
 * against a specific policy name.
 * 
 * @param {object} idTokenClaims - The claims contained within the ID token
 * @param {string} policy - The specific B2C policy name to compare against
 * @returns {boolean} True if the policy matches, False otherwise.
 */
export const compareIssuingPolicy = (idTokenClaims, policy) => {
    const policyClaim = idTokenClaims.tfp || idTokenClaims.acr;

    /*Compare the claim value (case insensitive) with the provided policy name */
    return policyClaim && policyClaim.toLowerCase() === policy.toLowerCase();
};
