import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { b2cPolicies } from '../context/authConfig';
import { compareIssuingPolicy } from '../utils/claimUtils';
import { useNavigate } from 'react-router-dom';

const useAuthEvents = () => {
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        /* Register an event callback function */
        const callbackId = instance.addEventCallback((event) => {
            switch (event.eventType) {
                /*Handle successful login or token acquisition */
                case EventType.LOGIN_SUCCESS:
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    console.log("Evento de éxito:", event);
                /*Check if the token was issued under the "forgot password" policy */
                    if (event.payload.idTokenClaims && compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                        console.log("Redirigiendo al flujo de inicio de sesión después de cambiar la contraseña...");
                        /*Log out and redirect to auth page */
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        }).then(() => {
                            const signUpSignInFlowRequest = {
                                authority: b2cPolicies.authorities.signUpSignIn.authority,
                            };
                            instance.loginRedirect(signUpSignInFlowRequest);
                        }).catch((error) => {
                            console.error("Error durante el cierre de sesión:", error);
                        });
                    } else {
                        /*Clear the password reset redirection indicator when navigating */
                        sessionStorage.removeItem('passwordResetRedirected'); 
                    }
                    break;

/*Handle login failure, particularly if it's a "password reset" error */
                case EventType.LOGIN_FAILURE:
                    console.log("Error de login:", event.error);
                    if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                        console.log("Detectado error de contraseña olvidada, redirigiendo al flujo de restablecimiento...");
                        /*Create a request to reset the password */
                        const resetPasswordRequest = {
                            authority: b2cPolicies.authorities.forgotPassword.authority,
                            scopes: ["openid", "profile"],
                            loginHint: accounts.length > 0 ? accounts[0].username : "",
                        };
                        instance.loginRedirect(resetPasswordRequest).catch((error) => {
                            console.error("Error durante el flujo de restablecimiento:", error);
                        });
                    } else {
                        /*Log out and redirect to auth page */
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        }).then(() => {
                            const signUpSignInFlowRequest = {
                                authority: b2cPolicies.authorities.signUpSignIn.authority,
                            };
                            instance.loginRedirect(signUpSignInFlowRequest);
                        }).catch((error) => {
                            console.error("Error durante el cierre de sesión:", error);
                        });
                    } 
                    
                    break;

                case EventType.ACQUIRE_TOKEN_FAILURE:
                    /*Log out and redirect to auth page */
                    console.log("Error al adquirir token:", event.error);
                    instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                    }).then(() => {
                        const signUpSignInFlowRequest = {
                            authority: b2cPolicies.authorities.signUpSignIn.authority,
                        };
                        instance.loginRedirect(signUpSignInFlowRequest);
                    }).catch((error) => {
                        console.error("Error durante el cierre de sesión:", error);
                    });
                    
                    break;

                default:
                    break;
            }
        });

        /*Cleanup: Remove the event callback when the component is unmounted */
        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance, accounts, navigate]);

    return null;
};

export default useAuthEvents;
