import React from 'react';

const IconoUser = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    className={`iconoUser ${className}`}
    fill="none"
  >
    <circle cx="18" cy="17.575" r="17.5" fill="#2B306E" />
    <circle cx="18" cy="17.575" r="12" fill="#2B306E" stroke="white" />
    <path
      d="M17.5 16.8571C18.4093 16.8571 19.2814 16.4959 19.9244 15.8529C20.5673 15.21 20.9286 14.3379 20.9286 13.4286C20.9286 12.5193 20.5673 11.6472 19.9244 11.0042C19.2814 10.3612 18.4093 10 17.5 10C16.5907 10 15.7186 10.3612 15.0756 11.0042C14.4327 11.6472 14.0714 12.5193 14.0714 13.4286C14.0714 14.3379 14.4327 15.21 15.0756 15.8529C15.7186 16.4959 16.5907 16.8571 17.5 16.8571ZM16.2759 18.1429C13.6375 18.1429 11.5 20.2804 11.5 22.9187C11.5 23.358 11.8563 23.7143 12.2955 23.7143H22.7045C23.1437 23.7143 23.5 23.358 23.5 22.9187C23.5 20.2804 21.3625 18.1429 18.7241 18.1429H16.2759Z"
      fill="white"
    />
  </svg>
);

export default IconoUser;
