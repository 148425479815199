import React, { createContext, useContext, useState, useEffect } from 'react';
/*Detect if a device mobile */
import { isMobile } from 'react-device-detect';

/*Create a Context to share the sidebar state*/
const SidebarContext = createContext();

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(!isMobile); 
  useEffect(() => {
    const handleResize = () => {
// Collapse the sidebar on mobile devices, expand otherwise
      if (isMobile){
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };
    window.addEventListener('resize', handleResize);


    handleResize();
    return () => {
      // Add event listener to detect screen resize
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <SidebarContext.Provider value={{ isCollapsed, setIsCollapsed }}>
      {children}
    </SidebarContext.Provider>
  );
};
