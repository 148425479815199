
import React from 'react';

/*Main boards and role clasification*/
import ConstantsComponent from '../claims/Constants.jsx';
import BoardCliente from './Boards/Statistics/Board_Client';
import BoardSuper from './Boards/Statistics/Board_Super';

const Statistics = () => {
  /*Retrieve the role type from the ConstantsComponent */
  const { TYPE_ID} = ConstantsComponent();
  const mostrarBoardCliente = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 3;
  const mostrarBoardSuper = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 1;
  
  /*Determine which board to display based on the role type ID 
  
  const mostrarBoardUser = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 2;
  */

  /*Conditionally render the appropriate board based on the role type */
  return (
    <div className='container_graph'>
      {mostrarBoardCliente && <BoardCliente />}
      {mostrarBoardSuper && <BoardSuper />}
    </div>
  );
};

export default Statistics;
