import GraphBards2 from "../../Graphs/Repeated/GraphBars2.jsx";
const Board_content_client= ()=>{
//send user id to lient explorer board
    return (
            <div className="TabExplorerCliente_User">
                <GraphBards2 /> 
            </div>
        
    );
};
export default Board_content_client;