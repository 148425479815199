import React from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IoMdDownload } from "react-icons/io";
import ConstantsComponent from "../claims/Constants.jsx";
import UrlProvider from "../Api/UrlProvider.jsx";
const ExportToExcel = () => {
    const { COMPANY_ID } = ConstantsComponent();
    const Url = `${UrlProvider.defaults.baseURL}/Historical_client/export//${COMPANY_ID}`;
    const exportData = async () => {
        try {
            // Fetch data from the endpoint
            const response = await axios.get(Url);
            const data = response.data;

            // Convert JSON data to worksheet
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Historial');

            // Generate Excel file and trigger download
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Explorer_MentorIA.xlsx');
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    return (
        <div className="navbar-container"
        style={{ minWidth: '90px',maxWidth:'105px', minHeight:"30px"}}
        >
            <button style={{minWidth: '90px',maxWidth:'105px', minHeight:"30px"}} className="navbar-btn"  onClick={exportData}>
                <IoMdDownload 
                style=
                {{  margin: "0% 5% 0% 5%",
                    width: "13%",
                    height: "auto",
                    color:"white"}} />
                <div className='text'><h2>Export to Excel</h2></div>
            </button>
        </div>
    );
};

export default ExportToExcel;
