import React from 'react';
import Board_content_client from "../components/Boards/t_content/Board_content_client"
const Trends = () => {
  return (
    <div className='container_graph'>
      <Board_content_client />
    </div>
  );
};

export default Trends;