import React from 'react';
import '../styles/Popup.css';
import { ReactComponent as Giro } from '../assets/Giroicono.svg';

const Popup = ({ onClose }) => {
  return (
    <div className="popup-container">
      <div className="popup">
        <div className='cont'>
          <text1>Por favor, coloque su teléfono en orientación horizontal</text1>
          <div className='icono'>
            <Giro className='svg' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
