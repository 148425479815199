import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthenticationStatus from './layout/AuthenticationStatus';
import Datav from './layout/Basepage';
import { SidebarProvider } from './components/Sidebar/SidebarContext';    
import { EmbedDetailsProvider } from './components/EmbedDetailsProvider';
import AuthEventProvider from './context/AuthEventProvider';
import './styles/App.css';


function App() {

  return (
  /* Provides context for sidebar state */
    <SidebarProvider>
       {/* Wraps the application in a Router to handle client-side routing */}
      <Router>
      <AuthEventProvider />
      <EmbedDetailsProvider>
          <div className="App">
          <Routes>
            {/*routing depends de auth */}
            <Route path="/" element={<AuthenticationStatus />} />
            <Route path="/Datav/*" element={<Datav />} />
          </Routes>
          </div>
      </EmbedDetailsProvider>
      </Router>
    </SidebarProvider>
  );
}


export default App;
