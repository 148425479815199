import React from 'react';

const IconoBot = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    className={`iconoBot ${className}`}
    fill="none"
  >
    <circle cx="18" cy="18.075" r="17.5" fill="#2B306E" />
    <circle cx="18" cy="18.075" r="12" fill="#2B306E" stroke="white" />
    <path
      d="M23.8483 17.2822V15.3488C23.8483 15.004 23.7113 14.6734 23.4676 14.4297C23.2238 14.1859 22.8932 14.0489 22.5485 14.0489H18.649V13.1963C18.8472 13.0182 18.974 12.7621 18.974 12.4749C18.974 12.2163 18.8712 11.9684 18.6884 11.7855C18.5056 11.6027 18.2576 11.5 17.9991 11.5C17.7405 11.5 17.4926 11.6027 17.3098 11.7855C17.1269 11.9684 17.0242 12.2163 17.0242 12.4749C17.0242 12.7621 17.151 13.0182 17.3492 13.1963V14.0489H13.4497C13.105 14.0489 12.7744 14.1859 12.5306 14.4297C12.2869 14.6734 12.1499 15.004 12.1499 15.3488V17.2972L12.1031 17.3004C11.9393 17.3121 11.7859 17.3855 11.674 17.5057C11.5621 17.6259 11.4999 17.7841 11.5 17.9484V19.2482C11.5 19.4206 11.5685 19.5859 11.6904 19.7078C11.8122 19.8297 11.9775 19.8981 12.1499 19.8981V23.1477C12.1499 23.4924 12.2869 23.823 12.5306 24.0668C12.7744 24.3105 13.105 24.4475 13.4497 24.4475H22.5485C22.8932 24.4475 23.2238 24.3105 23.4676 24.0668C23.7113 23.823 23.8483 23.4924 23.8483 23.1477V19.8981C24.0206 19.8981 24.186 19.8297 24.3078 19.7078C24.4297 19.5859 24.4982 19.4206 24.4982 19.2482V17.9887C24.5057 17.8878 24.4897 17.7866 24.4514 17.693C24.3201 17.3758 24.0387 17.2998 23.8483 17.2822ZM14.7495 17.9484C14.7495 17.2309 15.1863 16.6486 15.7244 16.6486C16.2625 16.6486 16.6993 17.2309 16.6993 17.9484C16.6993 18.6659 16.2625 19.2482 15.7244 19.2482C15.1863 19.2482 14.7495 18.6659 14.7495 17.9484ZM20.5974 21.8479C19.9469 21.8459 15.3995 21.8479 15.3995 21.8479V20.548C15.3995 20.548 19.9495 20.5467 20.6 20.548L20.5974 21.8479ZM20.2738 19.2482C19.7357 19.2482 19.2989 18.6659 19.2989 17.9484C19.2989 17.2309 19.7357 16.6486 20.2738 16.6486C20.8119 16.6486 21.2486 17.2309 21.2486 17.9484C21.2486 18.6659 20.8119 19.2482 20.2738 19.2482Z"
      fill="white"
    />
  </svg>
);

export default IconoBot;
