import React, { useState, useEffect,useRef} from 'react';



const TotalMessages = ({id,type_id,Key,Url,Data}) => {
  const [data, setData] = useState([]);
  const refDiv = useRef(null);
  const [chartSize, setChartSize] = useState(300);

/*Responsive container takes container dimensions for resizes */
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentRect.width > 0 && entry.contentRect.height > 0) {
          setChartSize(Math.min(entry.contentRect.width, entry.contentRect.height));
        }
      }
    });
    resizeObserver.observe(refDiv.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

/* Data Obtain */
  useEffect(() => {
    let url = Url;
    let key = Key;

    fetch(url)
      .then(response => response.json())
      .then(data => { 
        const formattedData = data.map(item => ({
          value: item[key]
        }));
        setData(formattedData);
        
      })
      .catch((error) => {
      });
  }, [id,type_id]);

  return (
    <div ref={refDiv} className='Cards'>
      {data.length > 0 ? (
        <div className='numbmesages'>
          <ul>
            {data.map((item, index) => (
              <li key={index}>{`${item.value}`}</li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
        <h3>{Data} </h3>
    </div>

  );
};

export default TotalMessages;
