import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, Cell } from 'recharts';
import { useCSSVariable } from '../../../utils/Colors';
import UrlProvider from "../../../Api/UrlProvider";
import { scaleLinear } from 'd3-scale';
import { interpolateRgb } from 'd3-interpolate';
import { IoFilter } from "react-icons/io5";

// Componente de función personalizado para renderizar etiquetas con estilos y color de la barra
const CustomLabel = (props) => {
  const { x, y, width, value, index, colors } = props;
  const labelStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    fill: colors[index],
    textAnchor: 'middle'
  };

  return (
    <text x={x + width / 2} y={y} dy={-10} style={labelStyle}>
      {value}%
    </text>
  );
};

const GraphBars2 = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [categoryData, setCategoryData] = useState([]);

  const [chartSize, setChartSize] = useState(0);
  const refDiv = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        requestAnimationFrame(() => {
          let newSize = Math.min(entry.contentRect.width, ((entry.contentRect.height) - entry.contentRect.height / 3.5));
          setChartSize(newSize);
        });
      }
    });
    resizeObserver.observe(refDiv.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const url = `${UrlProvider.defaults.baseURL}/tendencias`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setData(data);

        const uniqueCategories = Array.from(new Set(data.map(item => item.category)));
        setSelectedCategories(uniqueCategories);
        setAvailableCategories(uniqueCategories);

        if (data.length > 0) {
          const maxDate = new Date(Math.max(...data.map(item => new Date(item.timestamp))));
          const minDate = new Date(maxDate);
          minDate.setDate(maxDate.getDate() - 7);

          setStartDate(minDate.toISOString().split('T')[0]);
          setEndDate(maxDate.toISOString().split('T')[0]);
        }
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const adjustTimestamp = (dateString) => {
    const date = new Date(dateString);
    // Restar 5 horas al timestamp
    date.setHours(date.getHours() - 5);
    return date;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    let filtered = Array.isArray(data) ? data : [];
    const start = startDate ? formatDate(startDate) : null;
    const end = endDate ? formatDate(endDate) : null;

    if (start && end) {
      filtered = filtered.map(item => {
        // Ajustar el timestamp restando 5 horas
        const adjustedDate = adjustTimestamp(item.timestamp);
        return {
          ...item,
          adjustedTimestamp: adjustedDate
        };
      });

      filtered = filtered.filter(item => {
        const itemDate = formatDate(item.adjustedTimestamp);
        return itemDate >= start && itemDate <= end;
      });
    }

    const availableCategoriesSet = new Set(filtered.map(item => item.category));
    setAvailableCategories(Array.from(availableCategoriesSet));

    if (selectedCategories.length > 0) {
      filtered = filtered.filter(item => selectedCategories.includes(item.category));
    }
    console.log("Filtered data:", filtered); // Debugging line
    setFilteredData(filtered);

    const total = filtered.length;
    setTotalMessages(total);

    const categoryCounts = filtered.reduce((acc, item) => {
      if (item.category) {
        acc[item.category] = (acc[item.category] || 0) + 1;
      }
      return acc;
    }, {});

    const categoryData = Object.keys(categoryCounts).map(category => ({
      category,
      count: categoryCounts[category],
      percentage: total > 0 ? ((categoryCounts[category] / total) * 100).toFixed(2) : 0,
    }));
    console.log("Category data:", categoryData); // Debugging line

    setCategoryData(categoryData);
  }, [data, startDate, endDate, selectedCategories]);

  const handleFocus = (e) => {
    e.target.type = 'date';
  };

  const handleBlur = (e) => {
    if (e.target.value === '') {
      e.target.type = 'text';
    }
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    setSelectedCategories(prev =>
      checked ? [...prev, value] : prev.filter(category => category !== value)
    );
  };

  let yAxisFontSize = '1.6vh';
  if (window.innerHeight <= 500) {
    yAxisFontSize = '3vh';
  }
  if (window.innerHeight <= 390) {
    yAxisFontSize = '2.5vh';
  }

  const maxValue = Math.max(...categoryData.map(item => parseFloat(item.percentage)));

  const blue = useCSSVariable('--darkblue');
  const bluei = useCSSVariable('--lightblue');
  const blueM = useCSSVariable('--blue');
  const blueF = useCSSVariable('--middlegray');
  const colorArray = [blue, bluei, blueM, blueF];

  const colorScale = scaleLinear()
    .domain(colorArray.map((_, i) => i / (colorArray.length - 1)))
    .range(colorArray)
    .interpolate(interpolateRgb);

  const getCategoryColor = (index, totalCategories) => {
    if (totalCategories === 1) {
      return colorArray[0];
    }
    return colorScale(index / (totalCategories - 1));
  };

  const uniqueCategories = Array.from(new Set(data.map(item => item.category)));

  return (
    <div ref={refDiv} className='GraphBars_t'>
      <div className='titulo'>
        <h2>Tendencias</h2>
      </div>
      <div className='filtro'>
            <div className='first'>
              <div className='container-input'>
                <txt>Fecha inicio</txt>
                <input
                  type="date"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className='container-input'>
                <txt>Fecha Final</txt>
                <input
                  type="date"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
      <div className="contenedor-dos-columnas">
        <div className="columna-85">
          <div className='containerBar'>
            <ResponsiveContainer width="100%" height={chartSize}>
              <BarChart
                data={categoryData}
                margin={{
                  top: 30,
                  right:0,
                  left:0,
                  bottom: -10,
                }}
              >
                <CartesianGrid strokeDasharray="0 2" />
                <XAxis dataKey="category" tick={{ fontSize: yAxisFontSize }} axisLine={{ stroke: "#FFFFF" }} />
                <YAxis domain={[0, maxValue]} hide />
                <Tooltip />
                <Bar dataKey="percentage" name="Porcentaje">
                  {categoryData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getCategoryColor(index, categoryData.length)} />
                  ))}
                  <LabelList
                    dataKey="percentage"
                    position="top"
                    content={(props) => <CustomLabel {...props} colors={categoryData.map((_, index) => getCategoryColor(index, categoryData.length))} />}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="columna-15">
          <div className="container-input2">
            <h1><IoFilter style={{ marginRight: '3%' }} />  Filtros</h1>
            <h2>Categoría</h2>
            <div style={{marginBottom:"7%"}}>
              {uniqueCategories.map(category => (
                <label key={category} style={{ fontWeight:"540",color:"#595959",fontSize:"clamp(11px, 0.7vw, 14px)",display: 'block', marginBottom: '10px',marginInlineStart:"15%",display:"flex", justifyContent:"flex-start", alignContent:"center" }}>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    value={category}
                    checked={selectedCategories.includes(category)}
                    onChange={handleCategoryChange}
                    disabled={!availableCategories.includes(category)}
                    style={{ marginRight: '8px', color:"#595959"}}
                  />
                  {category}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphBars2;
