import React from 'react';

const Companyinf = ({ title, count}) => { // Añade Icon aquí
  return (
    <div className="Nameproduct">
        <div className="Company">{title}</div>
        <div className="Program">{count}</div>
      </div>
  );
};

export default Companyinf;