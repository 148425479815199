import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { loginRequest } from '../context/authConfig';

const UserClaims = () => {
  const { instance, accounts } = useMsal();
  const [userClaimsStrings, setUserClaimsStrings] = useState([]);
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);

  // Initialize MSAL instance if not already initialized
  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.initialize();
        setIsMsalInitialized(true);
      } catch (error) {
        console.error("Error al inicializar MSAL:", error);
      }
    };

    if (!isMsalInitialized) {
      initializeMsal();
    }
  }, [instance, isMsalInitialized]);

  const handleLogout = () => {
    if (isMsalInitialized) {
      instance.logoutRedirect({ postLogoutRedirectUri: "/" })
        .then(() => {
          const signUpSignInFlowRequest = {
            authority: loginRequest.authority,
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        })
        .catch((logoutError) => {
          console.error("Error durante el cierre de sesión:", logoutError);
        });
    } else {
      console.error("MSAL no está inicializado.");
    }
  };

  useEffect(() => {
    if (isMsalInitialized && accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance.acquireTokenSilent(request)
        .then((response) => {
          const claimsStrings = Object.entries(response.idTokenClaims)
            .map(([key, value]) => `${key}: ${value}`);
          setUserClaimsStrings(claimsStrings);
        })
        .catch((error) => {
          console.error("Error al adquirir el token silencioso: ", error);

          if (error.message.includes("No token found") || error.message.includes("invalid_grant")) {
            console.log("Token inválido o no encontrado, deslogueando usuario...");
            handleLogout();
          } else {
            handleLogout();
          }
        });

      const callbackId = instance.addEventCallback((event) => {
        if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
          console.error("Error al adquirir token:", event.error);
          handleLogout();
        }
      });

      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      };
    }
  }, [isMsalInitialized, instance, accounts]);

  return userClaimsStrings;
};

export default UserClaims;
