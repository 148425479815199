import { useState, useEffect } from 'react';
function useCSSVariable(varName) {
  /*State to store the value of the CSS variable */
    const [value, setValue] = useState('');

    useEffect(() => {
      /*Get the root element's styles */
      const rootStyle = getComputedStyle(document.documentElement);
      /*Retrieve the value of the CSS variable and remove any extra spaces */
      const varValue = rootStyle.getPropertyValue(varName).trim();
      /*Update the state with the fetched value */
      setValue(varValue);
    }, [varName]);

    return value;
  }
  
  export {useCSSVariable};
  