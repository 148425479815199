import ConstantsComponent from "../../../claims/Constants.jsx";
import TabHistoMsg from "../../Graphs/Client/TabHistoClient.jsx";
import UrlProvider from "../../../Api/UrlProvider.jsx";
const Board_ExplorerUser= ()=>{
//send user id to lient explorer board
    const {ID} = ConstantsComponent();
    /*Url */
    const Url=`${UrlProvider.defaults.baseURL}/Historical_client/${ID}`
    return (
            <div className="TabExplorerCliente_User">
                <TabHistoMsg id={ID} Url={Url} /> 
            </div>
        
    );
};
export default Board_ExplorerUser;