import React from 'react';

const SearchIcon = ({ className = '' }) => (
  <svg 
  xmlns="http://www.w3.org/2000/svg" 
  className={`SearchIcon ${className}`}
  viewBox="0 0 10 10" 
  fill="none"
  >
    <path d="M3.71429 0C4.69938 0 5.64412 0.391325 6.34068 1.08789C7.03725 1.78445 7.42857 2.7292 7.42857 3.71429C7.42857 4.63429 7.09143 5.48 6.53714 6.13143L6.69143 6.28571H7.14286L10 9.14286L9.14286 10L6.28571 7.14286V6.69143L6.13143 6.53714C5.45746 7.11244 4.6004 7.42852 3.71429 7.42857C2.7292 7.42857 1.78445 7.03725 1.08789 6.34068C0.391325 5.64412 0 4.69938 0 3.71429C0 2.7292 0.391325 1.78445 1.08789 1.08789C1.78445 0.391325 2.7292 0 3.71429 0ZM3.71429 1.14286C2.28571 1.14286 1.14286 2.28571 1.14286 3.71429C1.14286 5.14286 2.28571 6.28571 3.71429 6.28571C5.14286 6.28571 6.28571 5.14286 6.28571 3.71429C6.28571 2.28571 5.14286 1.14286 3.71429 1.14286Z" fill="#595959"/>
  </svg>
);

export default SearchIcon;
