import UrlProvider from "../../../Api/UrlProvider.jsx";
import ConstantsComponent from "../../../claims/Constants.jsx";
import Cards from "../../Graphs/Repeated/Cards.jsx";
import InfoUserCard from "../../Graphs/Repeated/InfoUserCard.jsx";
import GraphBars from "../../Graphs/Repeated/GraphBars.jsx"
import Radar from "../../Graphs/Client/RadarClient.jsx";
import Card2 from "../../../components/Graphs/Admin/Card2.jsx";


const BoardCliente= ()=>{
    /*Claims */
    const { ROL, ID, NAME, EMAIL, TYPE_ID,COMPANY_ID} = ConstantsComponent();
    /*New version */
    const Url_totalCM=`${UrlProvider.defaults.baseURL}/Total_messages_Admin/1`;
    const KeyCMT="TotalMessages"
    const KeyCMB= "bot_response"
    const KeyCMU= "user_message"
    const DataCM= "Mensajes en total"

    /*Cards */
    const Url_totalU=`${UrlProvider.defaults.baseURL}/Total_messagesUser_by_company/${ID}/company/${COMPANY_ID}`;
    const KeyT="Total_Mensajes";
    const DataT= "Mensajes de Usuarios en total"

    const Url_totalM=`${UrlProvider.defaults.baseURL}/Total_users_by_company/${ID}/company/${COMPANY_ID}`;
    const KeyM="Total_Usuarios"
    const DataM= "Usuarios en total"
    
    const Url_totalC=`${UrlProvider.defaults.baseURL}/Total_messages_by_company/${ID}/company/${COMPANY_ID}`;
    const KeyC="Total_Conversaciones"
    const DataC= "Conversaciones en total"

    /*Graphs */
    /*Bars */
    const Url_messages=`${UrlProvider.defaults.baseURL}/messages_user_by_day/${ID}`
    const KeyDataM="message_count"
    const KeyDataC="Conversaciones"

    /*Radar*/
    const Url_radar= `${UrlProvider.defaults.baseURL}/Activity_conversations/${ID}`

    
    return (
            <div className="TabClient_Super">
                <InfoUserCard name={NAME} Email= {EMAIL} rol={ROL}/>
                <Cards id={ID} type_id={TYPE_ID} Key={KeyM} Url={Url_totalM} Data={DataM} />
                <Card2 id={ID} type_id={TYPE_ID} Key1={KeyCMB} Key2={KeyCMU}  Url={Url_totalCM} Data={DataCM} />
                <Cards id={ID} type_id={TYPE_ID} Key={KeyC} Url={Url_totalC} Data={DataC} />
                <Radar id={ID} Url={Url_radar} />
                <GraphBars type_id={TYPE_ID} id={ID} dataC={KeyDataC} dataM={KeyDataM} Url={Url_messages}/>
                
                
            </div>
        
    );
};
export default BoardCliente;